import ApiService from "@/services/shared/http/ApiService"

export default class AuthService extends ApiService {


    forgetPassword(data) {
        return this.POST("auth/forget-password", data)
    }

    resetPassword(data) {
        return this.POST("auth/reset-password", data, {}, false)
    }

    login(data) {
        return this.POST("auth/login-admin", data, {}, false)
    }

    adminLogin(data) {
        return this.POST("admins/login", data)
    }


    adminForgetPassword(data) {
        return this.POST("admin/forget-password", data)
    }

    adminResetPassword(data) {
        return this.POST("admin/reset-password", data)
    }
}
